import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import styled from "styled-components/macro";
import osIconsLogo from "./img/os-icons-logo.svg"

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

function BasicExample() {
  return (
    <Router>
      <div>
        <NavigationContainer>
          <Logo src={osIconsLogo} alt="OS Icons Logo" />
          <a href="https://www.producthunt.com/posts/os-icons?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-os-icons" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=269905&theme=dark" alt="OS Icons - 100+ minimalistic icons for iOS 14 in 4 colorways | Product Hunt" style={{width: "220px", height: "48px"}} width="220" height="48" /></a>
          {/* <PurchaseButton>Purchase &rarr;</PurchaseButton> */}
        </NavigationContainer>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
    </div>
  );
}

function About() {
  return (
    <div>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
    </div>
  );
}

const NavigationContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
`

const Logo = styled.img``

// const PurchaseButton = styled.a`
//   background-color: #ffffff;
//   border-radius: 50px;
//   padding: 16px 24px;
//   font-family: "undefined-medium", undefined-medium, monospace;
//   text-transform: uppercase;
//   letter-spacing: 2px;
//   font-size: 14px;
//   padding: 19px 24px 16px 24px;
//   transition: all 0.25s ease-in-out;
//   -ms-transition: all 0.25s ease-in-out;
//   -webkit-transition: all 0.25s ease-in-out;
//   box-shadow: 0px 16px 40px rgba(230, 142, 167, 0.24);
//   border: solid 1px #FFFFFF;
//   &:hover {
//     box-shadow: 0px 16px 40px rgba(176, 142, 230, 0.48);
//     cursor: pointer;
//     border: solid 1px #BEBEBE;
//     color: #823feb;
//   }
// `

export default BasicExample;