import React from "react";
import BasicExample from "../routes.js";

function NavBar() {
  return (
    <BasicExample>
    </BasicExample>
  );
}

export default NavBar;
