import React from "react";
import "./App.css";
import styled from "styled-components/macro";
import "./sass/app.scss";
import NavBar from "./components/NavBar";
import Device from "./components/Device";
import variantsIcon from "./img/variants-icon.svg"
import themesIcon from "./img/themes-icon.svg"
import updatesIcon from "./img/updates-icon.svg"
import wallpapersIcon from "./img/wallpapers-icon.svg"

function App() {
  return (
    <MainApp className="App">
      <NavBar />
      <Content>
        <Images>
          <Device />
          <CircleFilled />
          <CircleOutline />
        </Images>
        <Text>
          <MainTitle>Make your home screen uniquely yours</MainTitle>
          <SubTitle>A set of custom iOS icons made from scratch to make your iPhone more streamlined, sleek, uniform.</SubTitle>
          <FeatureHighlights>
            <Highlight>
              <Icon src={variantsIcon} alt="A circle, triangle, and rectangle combined loosely to make an icon" />
              100+ custom icons w/ variants
            </Highlight>
            <Highlight>
              <Icon src={themesIcon} alt="Four squares in a grid that are different shades of gray to make an icon" />
              4 icon themes included to mix &amp; match
            </Highlight>
            <Highlight>
              <Icon src={updatesIcon} alt="A square that looks like an app icon with a notification badge on it in the top right to make an icon" />
              Lifetime additions and updates
            </Highlight>
            <Highlight>
              <Icon src={wallpapersIcon} alt="Two rectangles in different shades next to each other to make an icon" />
              Setup guide &amp; wallpapers included
            </Highlight>
          </FeatureHighlights>
          {/* <PurchaseButton>Purchase &rarr;</PurchaseButton> */}
          <a class="gumroad-button" href="https://gum.co/ZmRVym" target="_blank" rel="noopener noreferrer" data-gumroad-single-product="true">Purchase &amp; Download Now</a>
          <NoteLink href="https://www.notion.so/OS-Icons-dce2cb63093b42239cb6e4f5ee9864a8" rel="noopener noreferrer" target="_blank">See what icons are included ↗</NoteLink>
          <FootNote>
            Made by <a href="https://twitter.com/shyboytm">Dennis Cortés</a>, © Trademarked 2020.
          </FootNote>
          <CircleOutlineSmall />
        </Text>
      </Content>
    </MainApp>
  );
}

const transition = "transition: all 0.25s ease-in-out; -ms-transition: all 0.25s ease-in-out; -webkit-transition: all 0.25s ease-in-out;";

const MainApp = styled.div`
  height: 100vh;
  overflow: hidden;
  @media (max-width: 1219px) {
    overflow-y: visible;
  }
`

const Images = styled.div`
  height: 500px;
  width: 45%;
  position: relative;
  display: inline-block;
  @media (max-width: 767px) {
    width: auto;
    height: auto;
    display: block
  }
`

const Text = styled.div`
  text-align: left;
  position: relative;
  @media (max-width: 767px) {
    width: auto;
  }
`

const CircleFilled = styled.div`
  background-color: #000000;
  border-radius: 100%;
  bottom: -400px;
  left: -400px;
  height: 800px;
  width: 800px;
  position: absolute;
  z-index: 1;
  @media (max-width: 767px) {
    height: 350px;
    width: 350px;
    bottom: -500px;
    left: -100px;
  }
`

const CircleOutline = styled.div`
  background-color: transparent;
  border-radius: 100%;
  bottom: 4rem;
  left: 0rem;
  height: 500px;
  width: 500px;
  border: solid 1px #000000;
  position: absolute;
  z-index: 2;
  @media (max-width: 767px) {
    height: 200px;
    width: 200px;
    bottom: -17rem;
    left: -6rem;
  }
`

const Content = styled.div`
  display: flex;
  margin: 32px 0;
  @media (max-width: 767px) {
    margin: 0 24px 40px 24px;
  }
`

const MainTitle = styled.h1`
  max-width: 35rem;
`

const SubTitle = styled.p`
  font-size: 16px;
  max-width: 35rem;
  line-height: 24px;
  opacity: 0.7;
`

const FeatureHighlights = styled.div`
  margin: 32px 0;
`

const Highlight = styled.p`
  display: flex;
  align-items: center;
  padding: 8px 0;
`

const Icon = styled.img`
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray ;
  font-size: 16px;
  user-drag: none;
`

// const PurchaseButton = styled.a`
//   display: inline-block;
//   background-color: #ffffff;
//   border-radius: 50px;
//   padding: 19px 24px 16px 24px;
//   font-family: "undefined-medium", undefined-medium, monospace;
//   text-transform: uppercase;
//   letter-spacing: 2px;
//   font-size: 14px;
//   ${transition};
//   border: solid 1px #FFFFFF;
//   box-shadow: 0px 16px 40px rgba(230, 142, 167, 0.24);
//   &:hover {
//     box-shadow: 0px 16px 40px rgba(176, 142, 230, 0.48);
//     cursor: pointer;
//     border: solid 1px #BEBEBE;
//     color: #823feb;
//   }
// `

const NoteLink = styled.a`
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  color: #000000;
  font-size: 14px;
  display: block;
  margin-top: 32px;
  ${transition};
  &:hover{
    opacity: 0.5;
  }
`

const FootNote = styled.p`
  font-size: 12px;
  opacity: 0.7;
  margin-top: 64px;
  a {
    ${transition};
    color: #000000;
    &:hover{
      opacity: 0.5;
    }
  }
`

const CircleOutlineSmall = styled.div`
  background-color: transparent;
  border-radius: 100%;
  bottom: -250px;
  right: -500px;
  height: 500px;
  width: 500px;
  border: solid 1px #000000;
  position: absolute;
  z-index: 2;
  @media (max-width: 767px) {
    bottom: -60px;
    right: -470px;
  }
`

export default App;
