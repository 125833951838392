import React from "react";
import styled from "styled-components/macro";
import imageOne from "../img/icon-gallery-1.png";
import imageTwo from "../img/icon-gallery-2.png";
import imageThree from "../img/icon-gallery-3.png";
import imageFour from "../img/icon-gallery-4.png";
import imageFive from "../img/icon-gallery-5.png";

function Device() {
  return (
    <DeviceContainer>
      <ImageDiv>
        <img src={imageOne} alt="iPhone Preview"></img>
        <img src={imageTwo} alt="iPhone Preview"></img>
        <img src={imageThree} alt="iPhone Preview"></img>
        <img src={imageFour} alt="iPhone Preview"></img>
        <img src={imageFive} alt="iPhone Preview"></img>
      </ImageDiv>
    </DeviceContainer>
  );
}

const DeviceContainer = styled.div`
  height: 600px;
  width: 300px;
  background-color: #000000;
  border-radius: 40px;
  position: absolute;
  left: 8rem;
  top: 5rem;
  transform: rotate(-10deg);
  border: solid 16px #282828;
  box-sizing: border-box;
  box-shadow: 0px 16px 64px 0px #000000 25%;
  z-index: 3;
  animation: rotation 20s infinite ease-in-out;
  @keyframes rotation {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
  @media (max-width: 767px) {
    transform: rotate(-10deg);
    left: 4rem;
    top: 800px;
    width: 200px;
    height: 397px;
    border-radius: 30px;
    border: solid 12px #282828;
    @keyframes rotation {
      0% {
        transform: none;
      }
      50% {
        transform: none;
      }
      100% {
        transform: none;
      }
  }
}
`

const ImageDiv = styled.div`
  height: 600px;
  width: 300px;
  @media (max-width: 767px) {
    border-radius: 18px;
    height: 375px;
    width: auto;
  }
  @keyframes slide {10%{opacity:1;} 30%{opacity:0;}}
  img:nth-child(1){animation-delay:0s;}
  img:nth-child(2){animation-delay:5s;}
  img:nth-child(3){animation-delay:10s;}
  img:nth-child(4){animation-delay:15s;}
  img:nth-child(5){animation-delay:20s;}
  img {
    user-drag: none;
    position: absolute;
    animation: slide 25s infinite;
    opacity:0;
    width: 100%;
    height: auto;
    border-radius: 24px;
    @media (max-width: 767px) {
      border-radius: 18px;
      height: 375px;
      width: auto;
    }
  }
  
`

export default Device;
